import React, { useState, useEffect, forwardRef } from 'react';
import { Container, Row, Col, Button, Label, Input, TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SideBar from '../Sidebar';
import DashboardNavbar from '../DashboardNavbar';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import PropTypes from 'prop-types';
// import { Editor } from 'primereact/editor';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { getPrograms } from '../../pages/core/_request';
import { getPackages } from '../../Auth/core/Auth_request';
import Meta from '../../../services/Meta';

const Payout = ({ direction, ...args }) => {

    const [startdate, setStartDate] = useState()
    const [enddate, setEndDate] = useState()
    const [offered, setOffered] = useState()
    const [plan, setPlan] = useState()
    const [program, setProgram] = useState([])
    const [packages, setPackages] = useState([])



    const fetchPrograms = async () => {
        getPrograms().then(res => {
            setProgram(res.data);
        }).catch(e => {
            console.log(e)
        })
    }

    const fetchPackeges = async () => {
        getPackages().then(res => {
            setPackages(res)
        }).catch(e => {
            console.log(e)
        })
    }

    useEffect(() => {
        if (Boolean(!startdate)) {
            setEndDate()
        }
    }, [startdate])




    useEffect(() => {
        fetchPrograms();
        fetchPackeges();
    }, [])

    const handleReport = (event) => {
        event.preventDefault()
        console.log("report", startdate, enddate, offered, plan)
    }

    const CustomDateinput = forwardRef(({ disable, ids, date, value, onClick }, ref) => {
        return <div className="input-container w-100" onClick={onClick} ref={ref}>
            <Input type="text" value={value} readOnly={true} className="form-control" disabled={disable} id={ids} placeholder="Select Date" required="" />
            <img src={`/assets/images/__Calender gray.png`} alt="calender" onClick={onClick} className="" />
        </div>
    })




    return (<>
    <Meta title={'Payout'}  />

        <section className="section-b-space h-100vh">
            {/* <DashboardNavbar /> */}
            <Row className='h-100 custome_heifht'>
                <Col lg="3" className='d-lg-flex d-none justify-content-end lft_side'>
                    <div className='col-xl-9 d-flex justify-content-center'>
                        <SideBar></SideBar>
                    </div>
                </Col>

                <Col lg="9" className='px-xl-4 py-4 scrol_right h-100'>
                    <Col xl="11" className='h-100'>
                        <div className='h-100 '>
                            <h2>Download Revenue Report</h2>
                            <div className='d-flex align-items-center'>
                                <p className='fw-bold me-3 text-dark'>• Personal bookkeeping</p>
                                <p className='fw-bold text-dark'>• Tax returns</p>
                            </div>

                            <form onSubmit={handleReport}>
                                <div className='custome_cad p-4 border_dathfh'>
                                    <Row>
                                        <Col md="6" xl="3">
                                            <div className="form-group">
                                                <Label className="form-label" for="s_date">
                                                    Start Date
                                                </Label>
                                                <div className="custome_dripo payout_s">
                                                    <DatePicker selected={startdate} className="p-2 rounded-5 w-100" onChange={(date) => setStartDate(date)} dateFormat="dd/MM/yyyy" customInput={<CustomDateinput ids={'s_date'} />} isClearable={true} />
                                                </div>
                                            </div>
                                        </Col>

                                        <Col md="6" xl="3">
                                            <div className="form-group">
                                                <Label className="form-label" for="e_date">
                                                    End Date
                                                </Label>
                                                <div className="custome_dripo payout_s">
                                                    <DatePicker selected={enddate} minDate={startdate} disabled={Boolean(!startdate)} className="p-2 rounded-5 w-100" onChange={(date) => setEndDate(date)} dateFormat="dd/MM/yyyy" id='e_date' customInput={<CustomDateinput disable={Boolean(!startdate)} ids={'e_date'} />} isClearable={true} />
                                                </div>
                                            </div>
                                        </Col>

                                        <Col md="6" xl="3">
                                            <div className="form-group custome_dripo mb-3">
                                                <Label className="form-label" htmlFor="Offered">
                                                    Program Offered
                                                </Label>
                                                <div className="custome_dripo payout_s">
                                                    <Select
                                                        isSearchable={false}
                                                        placeholder='All'
                                                        classNamePrefix="select"
                                                        inputId='Offered'
                                                        options={program}
                                                        getOptionLabel={(value) => value?.title}
                                                        getOptionValue={(value) => value?.id}
                                                        value={offered}
                                                        onChange={(e) => setOffered(e)}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#f4f7f7',
                                                                primary: '#125453',
                                                                primary50: '#f4f7f7'
                                                            },
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </Col>

                                        <Col md="6" xl="3">
                                            <div className="form-group custome_dripo mb-3">
                                                <Label className="form-label" htmlFor="plans">
                                                    Plan
                                                </Label>
                                                <div className="custome_dripo payout_s">
                                                    <Select
                                                        isSearchable={false}
                                                        placeholder='All'
                                                        classNamePrefix="select"
                                                        inputId='plans'
                                                        options={packages}
                                                        getOptionLabel={(value) => value?.package_name}
                                                        getOptionValue={(value) => value?.id}
                                                        value={plan}
                                                        onChange={(w) => setPlan(w)}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#f4f7f7',
                                                                primary: '#125453',
                                                                primary50: '#f4f7f7'
                                                            },
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className='d-flex align-items-center justify-content-start my-4'><button type='submit' className='border-0 btn_next me-3'>Download Report</button></div>
                                </div>
                            </form>

                        </div>
                    </Col>
                </Col>

            </Row>
        </section>
    </>

    )
}

export default Payout