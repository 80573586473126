import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import SideBar from '../Sidebar';
import DashboardNavbar from '../DashboardNavbar';
import { Link } from 'react-router-dom';
import { useAuth } from '../../Auth/core/Auth';
import { getMyProgram } from './request';
import 'rc-table/assets/index.css';
import Table from 'rc-table';
import cn from 'classnames';
import Meta from '../../../services/Meta';


const MyPurchse = () => {
    const [accountInfo, setAccountInfo] = useState(false)

    const { auth } = useAuth();
    const [loading, setLoading] = useState(false);
    const [myPrograms, setMyPrograms] = useState([]);


    function formatUnixTimestamp(unixTimestamp) {
        // Convert the Unix timestamp to milliseconds by multiplying by 1000
        const milliseconds = unixTimestamp * 1000;
        // Create a new Date object using the milliseconds
        const date = new Date(milliseconds);
        // Get the day, month, and year components from the date object
        const day = date.getDate();
        // JavaScript months are zero-based, so we need to add 1
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        // Concatenate the components to form the desired format
        const formattedDate = `${day} ${month} ${year}`;
        return formattedDate;
    }


    const columns = [
        {
            title: 'S.NO',
            dataIndex: 's_no',
            key: 's_no',
            render(o, row, index) {
                return <span>{index + 1}</span>;
            },
        },
        {
            title: 'Course',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Status',
            dataIndex: 'active_status',
            key: 'active_status',
            render(o, row, index) {
                return <span>{o == 1 ? "Active" : "Pending"}</span>;
            },
        },
        {
            title: 'Validity',
            dataIndex: 'valid_until',
            key: 'valid_until',
            //   render(o, row, index) {
            //     return <span>{formatUnixTimestamp(o)}</span>;
            //   },
        },
    ];



    const handleMyPrograms = () => {
        setLoading(true)
        const id = auth?.user_id;
        getMyProgram(id).then((res) => {
            setMyPrograms(res.data);
            setLoading(false)
        }).catch((err) => {
            console.log('err', err.message);
            setLoading(false)
        })
    }


    useEffect(() => {
        handleMyPrograms();
    }, [])


    return (<>
    <Meta title={'My Purchase'}  />

        <section className="section-b-space h-100vh" >
            {/* <DashboardNavbar /> */}
            <Row className='h-100 custome_heifht'>
                <Col lg="3" className='d-lg-flex d-none justify-content-end lft_side'>
                    <div className='col-xl-9 d-flex justify-content-center'>
                        <SideBar></SideBar>
                    </div>
                </Col>

                <Col lg="9" className='px-xl-4 py-4 scrol_right'>
                    <Col xl="11">
                        <div className=''>
                            <h2 className='mb-4'>Manage your purchases</h2>
                            {
                                loading ?
                                    <div className='pageLoading'>
                                        <span
                                            className={cn(
                                                'd-flex h-100vh w-100 flex-column align-items-center justify-content-center'
                                            )}
                                        >
                                            <span className={"loading"} />
                                        </span>
                                    </div> :
                                    myPrograms?.length > 0 ?
                                        <div className='mt-3'>
                                            <Table scroll={{ x: 500 }}  rowKey={record => record?.id} columns={columns} data={myPrograms} aria-labelledby="lblPeopleTable" />
                                        </div>
                                        :
                                        <div className='text-center my-3 box_empty'>
                                            <h3 className='mb-3 text-center pt-4'>No Active Purchases</h3>
                                            <p className='mb-3 text-center'>As soon as you find a suitable Mentor and book your first Session, you'll see it here</p>
                                            <Link to={'/mentor'}>
                                                <button type='button' className='btn_theme px-3 py-2 mb-4'> Start Subscription</button>
                                            </Link>
                                        </div>
                            }
                        </div>
                    </Col>

                </Col>

            </Row>
        </section>
    </>

    )
}

export default MyPurchse